import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/dist/submodules/button";
import { H1, P } from "@amzn/stencil-react-components/dist/submodules/text";
import { useTranslation } from "react-i18next";
import { Card } from "@amzn/stencil-react-components/card";
import { Flex, View } from "@amzn/stencil-react-components/layout";
import { CircularProgressbar } from "react-circular-progressbar";
import { LiveRegion, LiveRegionRole } from "@amzn/stencil-react-components/live-announcer";
import { detectCountryFromDomain, getLocale } from "src/config/locale-config";
import { addEventMetric, MetricsWindow } from "src/dependencies/adobe-analytic";
import { useParams } from "react-router-dom";

const eventTestMicrophone = "click of 'test microphone' button on Landing Page";
const eventPlayRecording = "click of 'play recording' button on Landing Page";

interface AudioVolumeProps {
  // Empty string if no device selected
  selectedAudioOutputDevice: string;
  stream: MediaStream;
  completedMicrophoneAudioCheckHandler: Function;
}

const RECORD_DURATION = 5000; // in miliseconds
const MIN_COUNTER_VALUE = 0;
const MAX_COUNTER_VALUE = 100;

export const TestMicrophoneSpeaker = ({
  selectedAudioOutputDevice,
  completedMicrophoneAudioCheckHandler,
  stream,
}: AudioVolumeProps) => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const { t } = useTranslation();
  const [timeCounter, setTimeCounter] = useState(MIN_COUNTER_VALUE);
  const [isRecording, setIsRecording] = useState(false);
  const [isPlayRecording, setIsPlayRecording] = useState(false);
  const [playRecord, setPlayRecord] = useState<null | "played" | "recorded">(null);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);

  const locale = getLocale();

  const startCountdown = useCallback(() => {
    let counter = 0;
    const calculateTimeCounter = setInterval(() => {
      counter += 2;
      setTimeCounter(counter);

      if (counter === MAX_COUNTER_VALUE) {
        setTimeCounter(0);
        // 50 iterations * 100ms = 5000ms (5 seconds)
        clearInterval(calculateTimeCounter);
      }
    }, 100);
  }, [timeCounter]);

  const startRecording = useCallback(() => {
    if (isRecording || isPlayRecording) return;
    addEventMetric(window as MetricsWindow, applicationId!, locale, eventTestMicrophone);
    try {
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorder.start();
      setIsRecording(true);
      const audioChunks: Blob[] = [];

      mediaRecorder.addEventListener("dataavailable", (event) => {
        audioChunks.push(event.data);
      });

      mediaRecorder.addEventListener("stop", async () => {
        // Safari doesn't like webm
        const supportedType = MediaRecorder.isTypeSupported("audio/webm") ? "audio/webm" : "audio/mpeg";

        const audioBlob = new Blob(audioChunks, { type: supportedType });

        setAudioBlob(audioBlob);
      });

      setTimeout(() => {
        mediaRecorder.stop();
        setIsRecording(false);
        setPlayRecord("recorded");
      }, RECORD_DURATION); // Stop recording after 5 seconds
    } catch (err) {
      console.error("Error accessing media devices.", err);
    }
  }, [stream, isRecording, isPlayRecording]);

  const playAudio = async () => {
    if (!audioBlob || isRecording || isPlayRecording) return;
    addEventMetric(window as MetricsWindow, applicationId!, locale, eventPlayRecording);

    if (audioBlob) {
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);

      // setSinkId is not supported by Safari https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/setSinkId#browser_compatibility
      if (selectedAudioOutputDevice && "setSinkId" in audio) {
        (audio as any).setSinkId(selectedAudioOutputDevice).catch((error: any) => console.error(error));
      } else {
        console.warn("Browser does not support output device selection.");
      }
      await audio.play().then(
        () => {
          // Only trigger on success to avoid repaint if playback fails
          startCountdown();
          setIsPlayRecording(true);

          setTimeout(() => {
            setIsPlayRecording(false);
            setPlayRecord("played");
            completedMicrophoneAudioCheckHandler(true);
          }, RECORD_DURATION);
        },
        () => {
          console.warn("Browser does not support audio playback");
          setIsPlayRecording(false);
          setPlayRecord(null);
          setTimeCounter(0);
        }
      );
    }
  };

  return (
    <Card width={"100%"} flex={"true"} flexDirection="column">
      <H1>{t("VNHE-PreCheck-Microphone-Speaker-Volume-Title")}</H1>
      {isRecording && (
        <LiveRegion aria-live="assertive" role={LiveRegionRole.Status}>
          {t("VNHE-PreCheck-Microphone-Speaker-Volume-TestMicrophone-LiveRegion-Recording")}
        </LiveRegion>
      )}
      {playRecord == "recorded" && !isRecording && !isPlayRecording && (
        <LiveRegion aria-live="assertive" role={LiveRegionRole.Status}>
          {t("VNHE-PreCheck-Microphone-Speaker-Volume-TestMicrophone-LiveRegion-Recording-Ended")}
        </LiveRegion>
      )}
      {playRecord == "played" && !isRecording && !isPlayRecording && (
        <LiveRegion aria-live="assertive" role={LiveRegionRole.Status}>
          {t("VNHE-PreCheck-Microphone-Speaker-Volume-TestMicrophone-LiveRegion-PlayRecording-Ended")}
        </LiveRegion>
      )}
      <P>{t("VNHE-PreCheck-Microphone-Speaker-Volume-Description")}</P>

      <Flex flexDirection="row" gridGap="S200" alignItems="center">
        <Button data-testid="test-microphone-button" onClick={startRecording} variant={ButtonVariant.Primary} style={{ width: "fit-content" }}>
          {t("VNHE-PreCheck-Microphone-Speaker-Volume-TestMicrophone")}
        </Button>
        <Button onClick={playAudio} variant={ButtonVariant.Primary} style={{ width: "fit-content" }}>
          {t("VNHE-PreCheck-Microphone-Speaker-Volume-PlayRecording")}
        </Button>

        <View style={{ width: 25, height: 25 }}>
          <CircularProgressbar value={timeCounter} maxValue={MAX_COUNTER_VALUE} />
        </View>
      </Flex>
    </Card>
  );
};
