import i18n from "i18next";
// import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";
import { detectCountryFromDomain, getLocale } from "src/config/locale-config";

const locale = getLocale();

import translationEN from "../../translations/translation-en-US.puff.json";
import translationGB from "../../translations/translation-en-GB.puff.json";

const getENTranslation = () => {
  return locale === "en-GB" ? translationGB.resources : translationEN.resources;
};

const resources = {
  en: {
    translation: getENTranslation(),
  },
};

i18n
  .use(initReactI18next)
  // .use(
  //   resourcesToBackend((language, namespace, callback) => {
  //     import(`../../translations/translation-${language}.puff.json`)
  //       .then((res) => {
  //         callback(null, res.resources);
  //       })
  //       .catch((error) => {
  //         callback(error, "not get the translation");
  //       });
  //   })
  // )
  .init({
    resources,
    lng: locale,
    fallbackLng: "en",
    debug: true,
    react: {
      useSuspense: false,
    },
    keySeparator: false,
  });

export default i18n;
