import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Flex, View } from "@amzn/stencil-react-components/dist/submodules/layout";
import { useCheckViewport } from "src/hooks/useCheckViewport";
import { H2, Text } from "@amzn/stencil-react-components/dist/submodules/text";
import moment from "moment";
import { detectCountryFromDomain, getLocale } from "src/config/locale-config";
import { addEventMetric, MetricsWindow } from "src/dependencies/adobe-analytic";
import { useParams } from "react-router-dom";

const eventPageLoad = "page load of 'Your virtual pre-hire appointment starts soon' page";

interface EarlyAppointmentProps {
  appointmentStartTimestamp: string;
}

export const EarlyAppointment = ({ appointmentStartTimestamp }: EarlyAppointmentProps) => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const { t } = useTranslation();
  const { isSmallViewport } = useCheckViewport();
  const titleRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (titleRef.current) titleRef.current.focus();
  }, []);

  const locale = getLocale();
  const formattedDate = moment(appointmentStartTimestamp).format("MMM D, YYYY [at] hh:mm A");

  useEffect(() => {
    addEventMetric(window as MetricsWindow, applicationId!, locale, eventPageLoad);
  }, []);

  return (
    <View
      style={{ maxWidth: 572, height: "100%", margin: isSmallViewport ? 0 : "auto" }}
      data-testid="early-appointment"
    >
      <H2 ref={titleRef} tabIndex={-1} style={{ marginBottom: 20, fontSize: 24, lineHeight: 1.5 }}>
        {t("VNHE-EarlyAppointment-Title")}
      </H2>
      <Flex flexDirection="column">
        <Text className="small-text">{t("VNHE-EarlyAppointment-Description")}</Text>
        <Text>{formattedDate}</Text>
        <Text style={{ marginTop: 10 }}>{t("VNHE-EarlyAppointment-Action-Description")}</Text>
      </Flex>
    </View>
  );
};
