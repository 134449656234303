import React, { useState, useEffect, useCallback, useRef } from "react";
import { H1, P, Text } from "@amzn/stencil-react-components/dist/submodules/text";
import { MessageBanner, MessageBannerType } from "@amzn/stencil-react-components/message-banner";
import { useTranslation } from "react-i18next";
import PageContainer from "src/components/Layout/PageContainer";
import { Devices } from "src/components/PreCheckDevice/Devices";
import Video from "src/components/PreCheckDevice/Video";
import { useParams } from "react-router-dom";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/dist/submodules/button";
import { Col, View } from "@amzn/stencil-react-components/dist/submodules/layout";
import { TestMicrophoneSpeaker } from "../components/PreCheckDevice/TestMicrophoneSpeaker";
import { detectCountryFromDomain, getLocale } from "src/config/locale-config";
import { addEventMetric, MetricsWindow } from "src/dependencies/adobe-analytic";

const eventOnLoad = "page load of 'Check audio and video' on Landing Page";
const eventClickStart = "click of 'Start' button on Landing Page";
const eventClickRescheduleAppointment = "click of 'Reschedule appointment' button on Landing Page";

interface PreCheckDeviceProps {
  onContinue: () => void;
}

export const PreCheckDevice = ({ onContinue }: PreCheckDeviceProps) => {
  const { applicationId, jobId } = useParams<{
    applicationId: string;
    jobId: string;
  }>();

  const { t } = useTranslation();

  const [haveAccessPermission, setHaveAccessPermission] = useState(true);
  const [allowsSpeakerSelection, setAllowsSpeakerSelection] = useState(true);
  const [isCompletedMicrophoneAudioCheck, setIsCompletedMicrophoneAudioCheck] = useState(false);
  const [selectedVideoDevice, setSelectedVideoDevice] = useState("");
  const [selectedAudioDevice, setSelectedAudioDevice] = useState("");
  const [selectedAudioOutputDevice, setSelectedAudioOutputDevice] = useState("");
  const [rawStream, setRawStream] = useState<MediaStream>();
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const titleRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (titleRef.current) titleRef.current.focus();
  }, []);

  const locale = getLocale();

  // Getting Media Access and devices
  useEffect(() => {
    const getDevices = async () => {
      // navigator.mediaDevices.enumerateDevices() will return an empty label attribute value if the permission for accessing the mediaDevices is not given.
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
        setHaveAccessPermission(true);
        setRawStream(mediaStream);
        addEventMetric(window as MetricsWindow, applicationId!, locale, eventOnLoad);

        // Get the devices from mediaDevices.
        await navigator.mediaDevices
          .enumerateDevices()
          .then((devices) => {
            if (devices.find((device) => device.kind === "audiooutput") === undefined) {
              // Safari and Firefox have a special setting to allow selecting output device,
              // we should not make candidates use developer settings so we use fallback
              setAllowsSpeakerSelection(false);
            }

            setDevices(devices);
          })
          .catch((error) => console.error(error));

        // Add an event listener to monitor for changes to the device list.
        const handleDeviceChange = () => {
          navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => {
              setDevices(devices);
            })
            .catch((error) => console.error(error));
        };

        navigator.mediaDevices.addEventListener("devicechange", handleDeviceChange);

        // Clean up the event listener
        return () => {
          navigator.mediaDevices.removeEventListener("devicechange", handleDeviceChange);
        };
      } catch (error) {
        setHaveAccessPermission(false);
      }
    };

    getDevices();
  }, []);

  // Each time user select new devices, reset MediaStream
  useEffect(() => {
    const video =
      selectedVideoDevice !== ""
        ? {
            deviceId: { exact: selectedVideoDevice },
          }
        : false;

    const audio =
      selectedAudioDevice !== ""
        ? {
            deviceId: { exact: selectedAudioDevice },
          }
        : false;

    const constraints = {
      video,
      audio,
    };

    if (constraints.video || constraints.audio) {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => setRawStream(stream))
        .catch((error) => {
          console.error(error);
          setHaveAccessPermission(false);
        });
    }
  }, [selectedAudioDevice, selectedVideoDevice, selectedAudioOutputDevice]);

  const handleRescheduleAppointment = () => {
    const contactUsURL = `${window.location.origin}/selfservice/appointment/new-hire-event/${applicationId}/${jobId}`;
    addEventMetric(window as MetricsWindow, applicationId!, locale, eventClickRescheduleAppointment);

    window.open(contactUsURL, "_blank");
  };

  const handleMediaChange = useCallback(
    (value: string, changeId: string) => {
      if (changeId === "video") {
        setSelectedVideoDevice(value);
      }
      if (changeId === "audio") {
        setSelectedAudioDevice(value);
      }
      if (changeId === "audio-output") {
        setSelectedAudioOutputDevice(value);
      }
    },
    [selectedVideoDevice, setSelectedAudioDevice, setSelectedAudioOutputDevice]
  );

  const continueHandler = () => {
    addEventMetric(window as MetricsWindow, applicationId!, locale, eventClickStart);
    onContinue();
  };

  const isEligibleToConnect =
    selectedVideoDevice &&
    selectedAudioDevice &&
    (selectedAudioOutputDevice || !allowsSpeakerSelection) &&
    isCompletedMicrophoneAudioCheck;

  return (
    <PageContainer>
      {!haveAccessPermission ? (
        <View style={{ marginBottom: 20 }}>
          <MessageBanner dismissButtonAltText="Dismiss" type={MessageBannerType.Error}>
            {t("VNHE-PreCheck-PermissionDenied")}
          </MessageBanner>
        </View>
      ) : null}
      <H1 ref={titleRef} tabIndex={-1}>
        {t("VNHE-PreCheck-Title")}
      </H1>
      <P>{t("VNHE-PreCheck-Description")}</P>
      <Video selectedVideoDevice={selectedVideoDevice} stream={rawStream} />
      <Devices
        devices={devices}
        handleMediaChange={handleMediaChange}
        selectedVideoDevice={selectedVideoDevice}
        selectedAudioDevice={selectedAudioDevice}
        selectedAudioOutputDevice={selectedAudioOutputDevice}
      />
      {rawStream && (
        <TestMicrophoneSpeaker
          stream={rawStream}
          selectedAudioOutputDevice={selectedAudioOutputDevice}
          completedMicrophoneAudioCheckHandler={setIsCompletedMicrophoneAudioCheck}
        />
      )}
      <Text margin={["S500", "0"]}>
        {t("VNHE-PreCheck-ContactUs")}
        <ul>
          <li>{t("VNHE-PreCheck-Trouble-CheckSound")}</li>
          <li>{t("VNHE-PreCheck-Trouble-DefaultDevice")}</li>
          <li>{t("VNHE-PreCheck-Trouble-OtherApps")}</li>
          <li>{t("VNHE-PreCheck-Trouble-BetterService")}</li>
          <li>{t("VNHE-PreCheck-Trouble-RecordPlay")}</li>
        </ul>
      </Text>
      <Col gridGap="S300">
        <MessageBanner isDismissible={false} type={MessageBannerType.Informational}>
          {t("VNHE-PreCheck-Start-Sound-Test-Description")}
        </MessageBanner>
        <Col>
          <Button
            data-testid="test-join-button"
            variant={ButtonVariant.Primary}
            disabled={!isEligibleToConnect}
            onClick={continueHandler}
          >
            {t("VNHE-PreCheck-JoinButton")}
          </Button>
          <Button variant={ButtonVariant.Secondary} onClick={handleRescheduleAppointment} style={{ marginTop: 10 }}>
            {t("VNHE-PreCheck-RescheduleAppointment")}
          </Button>
        </Col>
      </Col>
    </PageContainer>
  );
};
