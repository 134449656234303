import React from "react";
import amazonLogo from "../../assets/amazon-logo.png";
import { useTranslation } from "react-i18next";
import { Text } from "@amzn/stencil-react-components/dist/submodules/text";
import { Flex } from "@amzn/stencil-react-components/layout";

interface HeaderProps {
  children: React.ReactNode;
}

export const Header = ({ children }: HeaderProps) => {
  const { t } = useTranslation();

  return (
    <>
      <header>
        <Flex flexDirection="row">
          <img src={amazonLogo} width={150} />
          <Text style={{ marginLeft: 10, lineHeight: 1.8 }}>{t("VNHE-Header-Title")}</Text>
        </Flex>
      </header>
      <main>{children}</main>
    </>
  );
};
