import React, { useCallback } from "react";
import Person from "../assets/Person2.svg";
import PageWithIcon from "src/components/Layout/PageWithIcon";
import { ConnectWidgetErrorData } from "src/components/types";
import { NoAppointment } from "src/components/ErrorPage/NoAppointment";
import { EarlyAppointment } from "src/components/ErrorPage/EarlyAppointment";
import { GenericError } from "src/components/ErrorPage/GenericError";

interface AppointmentErrorProps {
  error?: ConnectWidgetErrorData;
}

export const AppointmentError = ({ error }: AppointmentErrorProps) => {
  const renderError = useCallback(() => {
    switch (error?.cause) {
      case "NOT_FOUND":
      case "TOO_LATE":
        return <NoAppointment />;
      case "TOO_EARLY":
        return <EarlyAppointment appointmentStartTimestamp={error.appointmentStartTimestamp} />;
      default:
        return <GenericError error={`Not found error cause of ${error?.cause}`} />;
    }
  }, [error]);

  return (
    <PageWithIcon src={Person} data-testid="appointment-error-wrapper">
      {renderError()}
    </PageWithIcon>
  );
};
