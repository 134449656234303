import React, { useEffect } from "react";
import { Col, Flex } from "@amzn/stencil-react-components/dist/submodules/layout";
import { Select } from "@amzn/stencil-react-components/form";
import { Label } from "@amzn/stencil-react-components/dist/submodules/text";
import { useCheckViewport } from "src/hooks/useCheckViewport";
import { useTranslation } from "react-i18next";
import { detectCountryFromDomain, getLocale } from "src/config/locale-config";
import { useParams } from "react-router-dom";
import { addEventMetric, MetricsWindow } from "src/dependencies/adobe-analytic";

const eventOnSelectCamera = "selected value from dropdown for camera on Landing Page";
const eventOnSelectMicrophone = "selected value from dropdown for microphone on Landing Page";
const eventOnSelectSpeaker = "selected value from dropdown for speaker on Landing Page";

interface DevicesProps {
  devices: MediaDeviceInfo[];
  selectedVideoDevice: string;
  selectedAudioDevice: string;
  selectedAudioOutputDevice: string;
  handleMediaChange: Function;
}

export const Devices = ({
  devices,
  handleMediaChange,
  selectedVideoDevice,
  selectedAudioDevice,
  selectedAudioOutputDevice,
}: DevicesProps) => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const { isSmallViewport } = useCheckViewport();
  const { t } = useTranslation();

  const mappedVideoSelectList = devices.filter((device) => device.kind === "videoinput");
  const mappedAudioSelectList = devices.filter((device) => device.kind === "audioinput");
  const mappedAudioOutputSelectList = devices.filter((device) => device.kind === "audiooutput");

  const locale = getLocale();

  const renderOption = (device: MediaDeviceInfo) => device.label;
  const valueAccessor = (device: MediaDeviceInfo) => device.label;
  const onChangeHandler = (value: string, changeId: string) => handleMediaChange(value, changeId);

  useEffect(() => {
    if (selectedAudioDevice === "" && mappedAudioSelectList.length > 0) {
      handleMediaChange(mappedAudioSelectList[0].deviceId, "audio");
    }
    if (selectedAudioOutputDevice === "" && mappedAudioOutputSelectList.length > 0) {
      handleMediaChange(mappedAudioOutputSelectList[0].deviceId, "audio-output");
    }
    if (selectedVideoDevice === "" && mappedVideoSelectList.length > 0) {
      handleMediaChange(mappedVideoSelectList[0].deviceId, "video");
    }
  }, [
    selectedVideoDevice,
    selectedAudioDevice,
    selectedAudioOutputDevice,
    mappedVideoSelectList,
    mappedAudioSelectList,
    mappedAudioOutputSelectList,
  ]);

  const selectedVideoDeviceToOptions = mappedVideoSelectList.filter(
    (device) => device.deviceId === selectedVideoDevice
  )[0];
  const selectedAudioDeviceToOptions = mappedAudioSelectList.filter(
    (device) => device.deviceId === selectedAudioDevice
  )[0];
  const selectedAudioOutputDeviceToOptions = mappedAudioOutputSelectList.filter(
    (device) => device.deviceId === selectedAudioOutputDevice
  )[0];

  return (
    <Flex
      flexDirection={isSmallViewport ? "column" : "row"}
      justifyContent={isSmallViewport ? "row" : "space-between"}
      margin={{ bottom: 20 }}
      style={{ zIndex: 0 }}
    >
      <Col
        gridGap="S200"
        style={isSmallViewport ? { marginBottom: 10 } : { width: "30%" }}
        data-testid="video-input-container"
      >
        <Label htmlFor="video-input-id" id="video-input-label" data-testid="video-input-label">
          {t("VNHE-PreCheck-Devices-Camera-Label")}
        </Label>
        <Select
          placeholder={t("VNHE-PreCheck-Devices-Placeholder")}
          labelId="video-input-label"
          options={mappedVideoSelectList}
          renderOption={renderOption}
          renderNativeOption={renderOption}
          valueAccessor={valueAccessor}
          value={selectedVideoDeviceToOptions}
          onChange={(e: MediaDeviceInfo) => {
            onChangeHandler(e.deviceId, "video");
            addEventMetric(window as MetricsWindow, applicationId!, locale, eventOnSelectCamera);
          }}
        />
      </Col>
      <Col
        gridGap="S200"
        style={isSmallViewport ? { marginBottom: 10 } : { width: "30%" }}
        data-testid="audio-input-container"
      >
        <Label htmlFor="audio-input-id" id="audio-input-label" data-testid="audio-input-label">
          {t("VNHE-PreCheck-Devices-Microphone-Label")}
        </Label>
        <Select
          placeholder={t("VNHE-PreCheck-Devices-Placeholder")}
          labelId="audio-input-label"
          options={mappedAudioSelectList}
          renderOption={renderOption}
          renderNativeOption={renderOption}
          valueAccessor={valueAccessor}
          value={selectedAudioDeviceToOptions}
          onChange={(e: MediaDeviceInfo) => {
            onChangeHandler(e.deviceId, "audio");
            addEventMetric(window as MetricsWindow, applicationId!, locale, eventOnSelectMicrophone);
          }}
        />
      </Col>
      <Col gridGap="S200" style={isSmallViewport ? {} : { width: "30%" }} data-testid="video_output-input-container">
        <Label htmlFor="audioOutput-input-id" id="audioOutput-input-label" data-testid="audioOutput-input-label">
          {t("VNHE-PreCheck-Devices-Speaker-Label")}
        </Label>
        <Select
          placeholder={t("VNHE-PreCheck-Devices-Placeholder")}
          labelId="audioOutput-input-label"
          options={mappedAudioOutputSelectList}
          renderOption={renderOption}
          renderNativeOption={renderOption}
          valueAccessor={valueAccessor}
          value={selectedAudioOutputDeviceToOptions}
          onChange={(e: MediaDeviceInfo) => {
            onChangeHandler(e.deviceId, "audio-output");
            addEventMetric(window as MetricsWindow, applicationId!, locale, eventOnSelectSpeaker);
          }}
        />
      </Col>
    </Flex>
  );
};
